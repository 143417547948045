import React from "react"
import { Link, graphql } from "gatsby"

import DefaultLayout from "../layouts/default"
import Image from "../components/image"
import SEO from "../components/seo"

import { groupBy, getDateYear } from "../utils"

const postBlock = (post) => {
  let dateBlock
  if (post.node.frontmatter.enddate) {
      dateBlock = <small>{post.node.frontmatter.date} - {post.node.frontmatter.enddate}</small>
  }
  else {
    dateBlock = <small>{post.node.frontmatter.date} - present</small>
  }
  const acquiredBlock = (post.node.frontmatter.acquired) ? <small style={{float:"right", color:"#006400"}}><em>* acquired by {post.node.frontmatter.acquiredBy}</em></small> : null
  return (
    <li key={post.node.id}>
        <div>
          <div className="title">
            <strong><Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link></strong>
            <div className="post-date code" style={{float:"right"}}>
              <Link to={post.node.fields.slug}>
                {dateBlock}<br />
                {acquiredBlock}
              </Link>
            </div>
          </div>
          <i><Link to={post.node.fields.slug}>{post.node.frontmatter.subtitle}</Link></i>
          <br />
          <Link to={post.node.fields.slug}>{post.node.frontmatter.extratitle}</Link>
        </div>
      </li>
  )
}

const IndexPage = ({ data }) => {

  const posts = data.allMarkdownRemark.edges.filter(
    p => p.node.frontmatter.type == 'work'
  )
  
  const postsList = posts => posts.map(post => (postBlock(post)))
  const postsListContainer = <div >{postsList(posts)}</div>
  
  /*
  const postsListContainer = groupBy(posts, getDateYear)
    .map(({ year, posts }, i) => (
      <div key={i}>
        {postsList(posts)}
      </div>
    ))
    .reverse()
  */

  return (
    <DefaultLayout>
      <SEO title="Home" />
      <section>
        <ul>
          {postsListContainer}
        </ul>
        <ul>
          <li className="condensed">
            <strong>DOOR3</strong>
            <div className="title">
              <i>Director - Mobile Solutions Practice</i>
              <div className="post-date code"><small>September, 2010</small> - <small>May, 2011</small></div>
            </div>
          </li>
          <li className="condensed">
            <div className="title">
              <i>Technical Delivery Manager</i>
              <div className="post-date code"><small>May, 2009</small> - <small>September, 2010</small></div>
            </div>
          </li>
          <li className="condensed">
            <div className="title">
              <i>Senior Developer</i>
              <div className="post-date code"><small>September, 2008</small> - <small>May, 2009</small></div>
            </div>
          </li>
        </ul>
      </section>
    </DefaultLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {type: {eq: "work"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM, YYYY")
            enddate(formatString: "MMMM, YYYY")
            title
            subtitle
            extratitle
            sort_order
            type
            acquired
            acquiredBy
          }
        }
      }
    }
  }  
`
