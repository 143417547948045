import { Link } from "gatsby"
import React from "react"

const linkStyle = {marginRight:"10px"}
const HeaderDescription = ({}) => (
    <section>
    <center>
      <p>I am a entrepreneurial, problem centric and deeply technically product leader with experience as Head of Platform, Senior Product Manager, Founder and Lead Engineer for multiple venture backed startups, a TechCrunch Disrupt winner, Silicon Valley stalwart and Webby Award winning social network.</p>
      <p>
        <a href="/writing" className="textlink" style={linkStyle} >Writing</a>
        <a href="https://www.linkedin.com/in/jacksondeane/" target="_blank" className="textlink" style={linkStyle} >Linkedin</a>
        <a href="https://www.twitter.com/jacksondeane/" target="_blank" className="textlink" style={linkStyle} >Twitter</a>
        <a href="https://github.com/jacksondeane" target="_blank" className="textlink" style={linkStyle} >GitHub</a>
        <a href="https://producthunt.com/@jacksondeane" target="_blank" className="textlink" style={linkStyle} >ProductHunt</a>
        <a href="https://www.instagram.com/thefartymarty/" target="_blank" className="textlink" style={linkStyle} >Family</a>
      </p>
    </center>
    </section>
)

export default HeaderDescription
